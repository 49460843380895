import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import PageTitle from "../../Share/PageTitle";
import LineItem from "./LineItem";
import ResumeCardTwo from "./ResumeCardTwo";
import {
  Cpu,        // Represents computer/processing power
  Code,       // Symbolizes programming and coding
  Layers,     // Suggests technical stacks and layered skills
  Radar,      // Implies technical scanning or expertise
  Processor,  // Direct representation of technical skills
  Cog,        // Represents technical mechanisms
  Terminal,   // Classic icon for programming and tech skills
} from 'lucide-react';

const ResumeTwo = () => {
  const { lineArray } = UseData();

  // Diviser le tableau en deux parties
  const midIndex = Math.ceil(lineArray.length / 2);
  const firstHalf = lineArray.slice(0, midIndex);
  const secondHalf = lineArray.slice(midIndex);

  return (
    <>
      <PageTitle title="Resume"></PageTitle>
      {/* End pagetitle */}

      <section className="bg-white lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade">
          <div className="container sm:px-5 md:px-10 lg:px-14">
            <div className="py-12 px-4 md:px-0">
              {/* Resume page title */}
              <h2 className="after-effect ">Mon parcours</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-6 gap-y-6 mt-[30px]">
                {/* Resume items map */}
                <ResumeCardTwo />
              </div>
            </div>
          </div>
          {/* End .container */}

          <div className="container bg-color-810 dark:bg-[#0D0D0D] py-12 px-2 sm:px-5 md:px-10 lg:px-20">
            <div className="grid grid-cols-1 gap-8">
              <div className="col-span-1">
                <h4 className="text-5xl dark:text-white font-medium mb-6 flex items-center">
                  <div className="mr-4">
                    <Terminal size={35} className="text-[#F95054]" />
                  </div>
                  Compétences techniques
                </h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="col-span-1">
                  {/* Afficher la première moitié des compétences */}
                  {firstHalf.map((item, i) => (
                    <LineItem item={item} key={i} />
                  ))}
                </div>

                <div className="col-span-1">
                  {/* Afficher la deuxième moitié des compétences */}
                  {secondHalf.map((item, i) => (
                    <LineItem item={item} key={i} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* End .container */}

          <Footer condition={false} bg={"#FFFF"} />
        </div>
      </section>
    </>
  );
};

export default ResumeTwo;